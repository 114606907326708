@import "../../../globalVar";

.ForecastStockItem{
  display: flex;
  flex-direction: row;
  .emph{
    color: $bs-primary;
    opacity: 0.8;
    margin-left: 3px;
  }
  .keyemph{
    margin-left: 8px;
  }
  .divider{
    margin: 3px 0;
    border-top: 1px solid gray;
  }
  .picture{
    width: 150px;
    margin-left: 30px;
    img{
      width: 146px;
      margin: auto;
    }
  }
  .header{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    .header1{
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 14pt;
      cursor: pointer;
      .code{
        color: $bs-primary;
        opacity: 0.7;
        
      }
      .name{
        margin-top: 0;
        color: $bs-primary;
        opacity: 0.7;
        margin-left: 10px;
        
      }
     
    }
    .type{
      display: flex;
      flex-direction: row;
      align-items: center;
      
    }
    .text1{
      color: $bs-secondary;
      font-size: 11pt;
      font-style: italic;
      margin-left: 5px;
    }
    
  }
  
  .text{
    width: 25%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 0 5px;
    
  }
  .params{
    padding: 0 5px;
    color: gray;
    font-size: 10pt;
    margin-top: 17px;
    .key{
      width: 150px;
      display: inline-block;
      margin-right: 3px;
    }
    .val{
      color: $bs-primary;
    }
    
  }
  .params1{
    width: 25%;
   
  }
  .params2{
    width: 25%;
    
  }
  .params3{
    width: 25%;
    
  }
  .weeks{
    border-top: 1px solid gray;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 5px;
    margin-bottom: 15px;
    .weekItem{
      display: flex;
      flex-direction: column;
      width: 68px;
      height: 40px;
      margin: 0 1px;
      .wNo{
        font-size: 8pt;
        text-align: center;
        color: gray;
      }
      .wBadge{
        width: 100%;
        margin: auto;
        
      }
      
    }
  }
  .actions{
    align-items: center;
    padding-top: 10px;
    margin-top: 5px;
    display: flex;
    border-top: 1px solid #dddcdc;
    .addToBasket{
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 10px;
      .number{
        width: 130px;
        padding: 5px;
        height: 25px;
        display: block;
      }
     
      .amount{
       margin-left: 10px;
        text-align: right;
       
      }
    }
  }
  .commentsSection{
    .commCont{
      display: flex;
      flex-direction: column;
      padding: 5px;
      background-color: $bs-light;
      border-radius: 5px;
      margin-top: 15px;
      .item{
        background-color: white;
        padding: 5px;
        display: flex;
        flex-direction: column;
        margin: 3px 10px;
        
        .head{
          display: flex;
          color: gray;
          margin-bottom: 1px;
          font-size: 10pt;
        }
      }
    }
  }
}