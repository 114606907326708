@import "../../globalVar";

.addCategory{
  .catString{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: $blue1;
    border-radius: 5px;
    h2{
      font-weight: normal;
      color: #fff;
      font-size: 14pt;
      margin: 0;
      padding: 5px;
    }
    .catStringItem{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      padding: 5px;
      .num{
        color: #fff;
        display: block;
        margin-right: 3px;
      }
      .value{
        display: block;
      }
    }
  }
  

  

}