@import '../../globalVar.scss';

.product-region {


 .box{

  border-radius: 5px;
  background-color: $lightGray;
  padding: 16px 20px 20px 20px;
  margin-bottom: 10px;
  margin-top: 10px;

 }

 .header {
  font-weight: bold;
  font-size: 16pt;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .item{
   margin-right: 10px;
   .icon{
    font-size: 12pt;
   }
  }
 }

 .row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
 }

 .row-item{
  display: flex;
  flex-direction: column;
  margin-right: 50px;
 }

 .btn{
  margin-top: 0px;
 }

 
}

