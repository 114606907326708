@import "src/globalVar";

.photoPrivateOnProduct {
  
  .btn-set-main{
    font-size: 10px;
    padding: 4px 8px;
    text-align: left;
    width: 220px;
    display: block;
    &:disabled{
      cursor: not-allowed;
    }
  }
  
  .photoStatusCont{
    margin-top: 20px;
  }
  
  .main-photo{
    font-size: 10px;
    padding: 6px 8px;
    background-color: #13653f;
    border-radius: 5px;
    color: #ffffff;
    text-align: left;
    display: block;
    width: 220px;
  }

  .link-download{
    margin-top: 30px;
    border-radius: 3px;
    padding: 3px;
    background-color: $bs-light;
    &:hover{
      text-decoration: underline;
      cursor: pointer;
    }
  }
  
  .photos {
    .cont {
      margin: 10px 0;
      .contItems {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        flex-wrap: wrap;
        justify-content: flex-start;

        .item {
          padding: 0px 10px;
          margin: 3px;
          border-radius: 3px;
          border: 1px solid $bs-secondary;
          display: flex;
          flex-direction: column;
          &:hover{
            border-color: $bs-primary;
          }
          .item2{
            margin-bottom: 5px;
            
            img{
              max-width: 200px;
              max-height: 200px;
            }
          }
          .ctrl{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            .icon{
              font-size: 44pt;
              color: $bs-secondary;
            }
            .btns{
              display: flex;
              flex-wrap: wrap;
              align-items: flex-start;
              margin-top: 15px;
              margin-left: 2px;
              width: 120px;
            }
          }

          .fileName{
            color: $bs-secondary;
            height: 80px;
          }

          .fileSize{
            font-size: 11pt;
            color: $bs-secondary;
            margin-bottom: 1px;
          }

          .infoBadges{
            height: 25px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: flex-start;
            .badge{
              margin: 2px;
            }
          }

        }

      }
    }
  }
}