@import '../../globalVar.scss' ;


.selectedCategory {
    background-color: $bs-primary !important;
    border: 1px solid $bs-primary !important;
    color: #fff !important;;
    padding: 10px;
}

.categories {
    .catString{
        position: fixed;
        right: 10px;
        top: 70px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        background-color: $bs-light;
        border-radius: 2px;
        box-shadow: 0 0 8px -3px rgba(0, 0, 0, 0.2);
        padding: 2px 8px;
        .icon{
            display: block;
            color: $bs-primary;
            font-size: 16pt;
            cursor: pointer;
            margin-right: 10px;
            padding: 3px;
            &:hover{
                color: $red;
            }
        }
        h2{
            font-weight: normal;
            color: $bs-primary;
            font-size: 12pt;
            margin: 0;
            padding: 3px;
        }
        .catStringItem{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;
            padding: 3px;
            .num{
                color: $bs-primary;
                display: block;
                margin-right: 3px;

            }
            .value{
                display: block;
            }
        }
    }
    
    .catStringEso{
        padding: 10px 20px;
        margin-left: 10px;
        border-radius: 5px;
        background-color: $bs-light;
        .delimeter{
            color: $gray2;
        }
    }

    .containerCat{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .containerItem{
            margin: 5px 10px;
            max-width: 400px;
            min-width: 360px;
        }
    }

    .search{
        margin-bottom: 10px;
        width: 100%;
    }

    .cat {
        padding: 20px 35px;
        margin-bottom: 10px;
        background-color: $bs-light;
        border-radius: 5px;
        height: 500px;
        overflow: auto;

        .item {

            cursor: pointer;
            padding: 5px;
            border-radius: 5px;
            border: 1px solid #fff;
            background-color: #fff;
            margin-bottom: 10px;
            box-shadow: 0 0 11px -3px rgba(0, 0, 0, 0.3);

            &:hover {
                border: 1px solid $blue1;
            }
        }
    }
}

.note{
    color: $gray;
    font-size: 14pt;
    font-style: italic;
}

.ctrl{
    margin-bottom: 35px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .item{
        margin: 5px;
    }
}