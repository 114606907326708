@import "../../../../globalVar";

.EsoAPi1002{
  padding: 10px;
  .cont{
    display: flex;
    flex-direction: row;
    padding: 10px;
    width: 100%;
    flex-wrap: wrap;
    .item{
      display: flex;
      padding: 10px;
      flex-direction: column;
      width: 330px;
      margin: 3px;
      .roww{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .key{
          margin-right: 10px;
          width: 100px;
          
        }
        .value{
          color: $bs-primary;
          
        }
      }
    }
  }
}

