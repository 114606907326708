@import '../../globalVar.scss';


.select-row {

  label{
    margin-top: 15px;
    margin-bottom: 5px;
  }

}

[type='radio'] {
  display: none;
}