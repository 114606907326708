@import '../globalVar.scss';

.error-icon{
  display: grid;
  align-items: center;
  color: $red;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  

  svg{
    margin-left: 5px;
    display: block;
  }
  p{
    margin-left: 5px;
    display: block;
  }
}
