@import "../../globalVar";
.tag{
  margin: 5px;
  display: flex;
  flex-direction: row;
  background-color: $bs-warning;
  padding: 5px 8px;
  border-radius: 20px;
  min-width: 100px;
  cursor: pointer;
  &:hover{
    opacity: 0.8;
  }
  .icon{
    margin-leftt: 10px;
  }

}

.edit{
  .tag{
    cursor: auto;
    .remove{
      cursor: pointer;
      &:hover{
        color: $red;
      }
    }
    //max-width: 250px;
  }
}