@import "../../../globalVar";

.alert-box {
  top: 10px;
  left: 10px;
  position: fixed;
  overflow: auto;
  flex-direction: column;
  z-index: 102;


}

._alert {
  display: flex;
  flex-direction: row;
  padding: 10px 10px;
  font-size: 10pt;
  max-width: 370px;
  align-items: center;
  border-radius: 5px;
  box-shadow: $boxShadow1;
  cursor: pointer;
  margin: 3px 0;
  &:hover{
    opacity: 0.8;
  }
 

  .text{
    padding: 2px 2px;
    display: flex;
    align-items: center;
    font-size: 12pt;
    .i{
      margin-right: 12px;
      font-size: 16pt;
    }
  }
  
}

.success {
  background-color: $bs-success;
  color: #fff;
 

}

.warning {
  background-color: $bs-danger;
  color: #fff;
  

}

.info {
  background-color: $bs-info;
  color: #fff;


}