$gray: #3e444a;
$gray2: #adb5bd;
$green: #98fb98;
$green2: #42ef42;
$greenDark: #299130;
$black: #000;
$blackLight: #3e444a;

$red: #dc3545;
$blueLink: #007bff;
$blue1: #80bdff;
$blue2: #96bffa;
$blue3: #11559d;
$orange: #fd7e14 !default;
$lightGray: #e8e8e8;
$middleGray: #6a6a6a;
$lightYellow: #eafbbd;
$tag: #f7bce0;

//bootstrap colors
$bs-primary: #0d6efd;
$bs-secondary: #6c757d;
$bs-success: #198754;
$bs-info: #0dcaf0;
$bs-danger: #dc3545 ;
$bs-warning: #ffc107;
$bs-light: #f8f9fa ; 
$bs-dark: #212529 ;



@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400&display=swap');


$mainFont: 'Open Sans', sans-serif;

/*ligth*/
$boxShadow1: 0 0 6px -1px rgba(0,0,0,0.4);
/*darker*/
$boxShadow2: 0px 0px 7px 1px rgba(0, 0, 0, 0.6);

