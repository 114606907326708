@import '../../globalVar.scss' ;

.edit{
  
  .esoCat{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5px;
    .code{
      border: 2px solid $bs-secondary;
      border-radius: 8px;
      color: $bs-secondary;
      padding: 5px 10px;
      font-weight: bold;
      margin: 1px 10px 1px 1px;
      
    }
    .cont{
      display: flex;
      flex-direction: row;
      align-items: center;
      .item{
        margin: 2px;
        background-color: $bs-secondary;
        padding: 5px 10px;
        border-radius: 8px;
        color: #fff;
      }
      .icon{
        background-color: #fff;
        color: $bs-secondary;
        padding: 5px 3px;
      }
    }
  }

  .select{
    width: 150px;
  }

  .locale {

    a:hover{
      text-decoration: underline;
    }

    a:visited {
      color: black;
    }
  }

  .selected{
    font-weight: bold;
  }

  .box-upload-photo {
    .btn-upload-photo{
      margin-top: 0;
      margin-bottom: 15px;
    }

    form {
      padding-top: 20px;
      padding-bottom: 20px;
      input[type="file"]{
        border: none;
        cursor: pointer;

      }
      input[type="button"]{
        cursor: pointer;
        width: 200px;
        &:hover{
          background-color: $blackLight;
          color: #fff;
        }
      }
    }




  }

  .preview-box {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;

    .product-photo-preview {
      width: 250px;
      img {
        width: 250px;
        border: 1px solid $blue1;
      }

      .remove-button {
        background: $red;
        color: #fff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        position: absolute;
        margin-top: 8px;
        margin-left: 224px;
      }

      .sale-photo{
        position: absolute;
        margin-top: 8px;
        margin-left: 8px;
        background: goldenrod;
        border: none;
        border-radius: 5px;
        padding: 5px;
        text-decoration: line-through;
      }

      .main-photo{
        position: absolute;
        margin-top: 8px;
        margin-left: 60px;
        background: $green;
        border: none;
        border-radius: 5px;;
        padding: 5px;
        text-decoration: line-through;

      }

      .sale-photo-disabled{
        position: absolute;
        margin-top: 8px;
        margin-left: 8px;
        background: goldenrod;
        border: none;
        border-radius: 5px;
        padding: 5px;
        font-size: 12px;

      }

    .main-photo-disabled
    {
      position: absolute;
      margin-top: 8px;
      margin-left: 60px;
      background: $green;
      border: none;
      border-radius: 5px;
      padding: 5px;
      font-size: 12px;
    }


    }
  }

  .locale-photos {
    margin-top: 40px;
  }

  .react-tabs__tab-list {
    margin: 0 0 20px;
    padding: 0;
    border: none;
  }
  .react-tabs__tab {
    border: 1px solid transparent;
    background-color: $lightGray;
    border-radius: 5px;
    margin: 5px;
    .i{
      margin-right: 5px;
    }
    &:hover{
      background-color: $blackLight;
      color: #fff;
    }
  }
  .react-tabs__tab--selected {
    background-color: $blueLink;
    color: #fff;
    border-radius: 5px;
  }
  .product-sync{
    .box{

      border-radius: 5px;
      background-color: $lightGray;
      padding: 16px 20px 20px 20px;
      margin-bottom: 20px;

    }

    .header {
      font-weight: bold;
      font-size: 16pt;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .item{
        margin-right: 10px;
        .icon{
          font-size: 12pt;
        }
      }
    }

    .row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
    }

    .row-item{
      display: flex;
      flex-direction: column;
      margin-right: 50px;
    }

    .btn1{
      margin-top: 15px;
    }
  }




}