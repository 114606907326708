.jsonStatus{
  .icon{
    margin-right: 10px;
  }

  a {
    color: gray;

    &:visited{
      color: gray;
    }

    &:hover{
      color: black;
    }
  }

  .found{
    color: green;
    display: flex;
    flex-direction: row;
  }

  .not-found{
    color: red;
    display: flex;
  }
}