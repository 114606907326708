@import "../../../globalVar"; 

.ForecastBasket{
    .basketCont{
        margin-top: 20px;
        
        .item{
            font-size: 15px;

            background-color: $bs-light;
            &:hover{
                background-color: #e9e9e9;
            }
            .closeItem{
                cursor: pointer;
                font-size: 16pt;
                width: 35px;
                color: red;
                svg{
                    display: block;
                }
            }
            .ref{
                width: 110px;
            }
            .name{
                width: 320px;
                font-size: 15px;
            }
            .amount{
                text-align: right;
                width: 120px;
                padding-left: 10px;
                .number{
                    width: 110px;
                    padding: 3px;
                    height: 23px;
                    text-align: right;
                }
            }
            .moq{
                width: 80px;
                text-align: right;
            }
            .kartonmj{
                width: 80px;
                text-align: right;
            }
            .mj{
                text-align: center;
                width: 40px;
            }
            .objem{
                width: 80px;
                text-align: right;
            }
            .karton{
                width: 110px;
                text-align: right;
            }
            .cena{
                width: 70px;
                text-align: right;
            }
            .cenaC{
                width: 120px;
                text-align: right;
            }
            .mena{
                width: 74px;
                text-align: center;
            }
            .error{
                width: 40px;
            }
            .save{
                width: 80px;
                text-align: right;
                button{
                    padding: 0 6px;
                }
            }
            .del{
                width: 30px;
                text-align: left;
                .icon{
                    color: red;
                    cursor: pointer;
                }
                
            }
        }
        .header{
            background-color: #e9e9e9;
        }

    }
    .basketPrehled{
        display: flex;
        flex-direction: column;
        font-size: 15pt;
        width: 390px;
        .item{
            width: 100%;
            margin: 3px 0;
            padding: 6px 20px;
            background-color: $bs-light;
            display: flex;
            flex-direction: row;
            border: 1px solid #dddddd;
            border-radius: 5px;
            .key{
                color: $bs-primary;
                margin-right: 10px;
                width: 50%;
            }
            .value{
                width: 50%;
                text-align: right;
               
            }
        }
    }
}
