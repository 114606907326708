@import '../../../globalVar.scss';



.custom-modalOverModal-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;

  .close {
    position: fixed;
    top: 10px;
    right: 10px;
    border: 0;

    button {
      outline: none !important;
      box-shadow: none !important;
      background-color: white;
      border: 0;
    }
  }
}

.custom-modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-box {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 92%;
  width: 96%;
  overflow: auto;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  z-index: 99999;
  padding: 20px;
  border-radius: 10px;
  .close{
    button{
      font-size: 16pt;
      color: $bs-primary;
      &:hover{
        color: $bs-danger;
      }
    }
  }
}