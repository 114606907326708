@import "../../globalVar.scss";

.addPhoto{
  
 
  
  .refCont{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    background-color: #fff3cd;
    padding: 20px 20px;
    width: 100%;

    .item{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      background-color: #fff;
      padding: 5px 10px;
      border-radius: 3px;
      align-items: center;
      margin-bottom: 3px;
      .i{
        color: $bs-danger;
        cursor: pointer;
        font-size: 15pt;
      }
    }
  }
}