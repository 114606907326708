@import 'globalVar.scss';


html, body, #root, #root>div {
  height: 100%
}

body {
  margin: 0;
  padding: 0;
  font-family: $mainFont;
}



input[type="checkbox"] {
  &:hover {
    cursor: pointer;
  }
}

a {
  text-decoration: none;
  padding: 0;
  margin: 0;
  display: block;
  box-sizing: border-box;
  color: $blue3;

  &:visited {
    color: $blue3;
  }

  &:hover {
    color: $blue3;
    text-decoration: underline;
  }
}

.i {
  margin-right: 10px;
}

/*input, textarea, select {
  border-radius: 5px;
  border: 1px solid $blue1;
  font-size: 12pt;
  padding: 2px 4px;

  &:focus{
    border: 1px solid $blueLink;
  }
}*/
textarea {
  padding: 8px;
}

title {
  color: red;
}

/*input,
select {
  height: 30px;
}*/

div {
  box-sizing: border-box;
}


.loading-indicator:before {
  content: '';
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

.loading-indicator:after {
  content: 'Loading';
  position: fixed;
  width: 100%;
  top: 50%;
  left: 0;
  z-index: 1001;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
}


.subMenu {
  padding: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 10px;

  .item {
    margin: 5px;
  }
}

//--------------bootstrap
.btn-group-vertical > .btn.active, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn:focus, .btn-group > .btn.active, .btn-group > .btn:active, .btn-group > .btn:focus{
  z-index: 0;
}
nav {
  box-shadow: $boxShadow2;

}

.navbar-brand {
  color: $bs-warning !important;
}

.navbar-dark {
  .navbar-nav {
    .nav-link {
      color: #fff;

      &:hover {
        color: $bs-warning;
      }


    }
  }
}


.input-M {
  display: flex;
  flex-direction: row;
  align-items: center;
  input[type=radio] {
    width: 20px;
    height: 20px;
    margin-top: 0;
    cursor: pointer;

  }
  label{
    padding-left: 0.5rem;
    cursor: pointer;
  }
}

//-----react-data-table


/*.rdt_TableHeadRow{
  background-color: $bs-dark !important;
}*/


//--------------colors
.cBS_warning {
  color: $bs-warning;
}

.cBG_BS_light{
  background-color: $bs-light;
}

.cBG_lightGray{
  background-color: $lightGray;
}

.c_white {
  color: #fff;
}

//--------btn buttons
/*button {
  cursor: pointer;
  outline: none;
  border: none;

  .i {
    margin-right: 10px;
  }
}*/

.btn1 {
  padding: 10px 15px;
  background-color: $blue1;
  border-radius: 4px;
  border: 1px solid $blue1;
  box-shadow: $boxShadow1;
  font-size: 11pt;

  .i {
    margin-right: 10px;
  }

  &:hover {
    background-color: $blueLink;
    color: #fff;
  }
}

.btn-filtr {
  padding: 12px 17px;
  background-color: $blueLink;
  border: none;
  border-radius: 4px;
  border: 1px solid $blue1;
  font-size: 13pt;
  color: #fff;

  .i {
    margin-right: 10px;
  }

  &:hover {
    background-color: $blue3;
    color: #fff;
  }
}

.btn-table-1 {
  background-color: $blue1;
  border: none;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;

  &:hover {
    background-color: $black;
  }
}

.btn-submit-blue {
  margin-top: 10px;
  border: 0;
  border-radius: 5px;
  background-color: $blue2;
  padding: 10px;

  &:hover {
    background-color: $gray;
    color: white;
  }
}

.btn-edit {
  border: 0;
  border-radius: 5px;
  background-color: $blueLink;
  padding: 6px 10px;
  font-size: 10pt;
  color: #fff;

  &:hover {
    background-color: $blue3;
  }
}

.btn-save {
  padding: 10px 15px;
  background-color: $green2;
  border-radius: 4px;
  border: 1px solid $green2;
  font-size: 14pt;

  &:hover {
    background-color: $blueLink;
    border: 1px solid $blueLink;
    color: #fff;
  }
}

.catalog-table {
  width: 100%;

  th,
  td {
    padding: 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
}

h1 {
  font-size: 30px;
  color: $bs-primary;
  margin: 20px 0;
  font-weight: bold;
}

h2 {
  font-size: 25px;
  color: $bs-primary;
  margin: 15px 0;
}

h3 {
  font-size: 18pt;
  color: $bs-primary;
  margin: 10px 0;
}

.dataTable {
  .rdt_TableBody {
    .rdt_TableRow {
      &:hover {
        background-color: $lightGray;
      }
    }
  }
}

/*form {

  label {
    margin-bottom: 5px;
  }

  .item-row {
    margin-right: 15px;
    flex-direction: row;

  }

  .item-group {
    display: flex;
    flex-direction: row;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    width: 100%;
  }

  !* label,
   input,
   textarea,
   select {
     display: block;
     width: 100%;
   }*!


  .form-item {
    padding-left: 10px;
    padding-right: 10px;
    align-items: center;
  }

  .form-item-row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}*/

.col-2 {
  width: 50%;
}

.col-5 {
  width: 20%;
}

.filtr {

  padding: 10px;
  margin-top: 5px;
  background-color: $lightGray;
  border-radius: 5px;

  .box {
    max-width: 50%;
  }

  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }


  .col-1 {
    max-width: 300px;
  }

  .col-2 {
    margin-left: 20px;
  }

  .radioFilter {
    padding: 5px;

    label {
      padding: 5px 15px;
      cursor: pointer;
      border: 1px solid $blue1;
      display: inline-block;

      &:hover {
        background-color: $blueLink !important;
        color: #fff !important;
        border-color: $blueLink;
      }
    }

    input[type="radio"] {
      display: none;
      background-color: white;
    }

    input[type="radio"]:checked + label {
      background-color: $blue1;
    }
  }

  .inputFilter {
    padding: 5px;

    input {
      width: 250px;
      display: inline-block;
    }
  }

  .filtrovat {
    padding: 5px;
    text-align: center;
    margin-top: 10px;
  }

}

.btn-link {
  color: $blueLink;
  font-size: 11pt;
  padding: 10px 15px;
  background-color: $lightGray;
  border-radius: 4px;
  border: 1px solid $blueLink;
  box-shadow: $boxShadow1;

  .i {
    margin-right: 10px;
  }

  &:hover {
    background-color: $blueLink;
    color: #fff;
    border: 1px solid $blueLink;
  }


}

a.link {
  color: $blueLink;
  font-size: 11pt;
  padding: 10px 15px;
  background-color: $lightGray;
  border-radius: 4px;
  box-shadow: $boxShadow1;
  border: 1px solid $blue1;

  .i {
    margin-right: 10px;
  }

  &:hover {
    background-color: $blueLink;
    color: #fff;
    border: 1px solid $blueLink;

  }


}

.btn-login {
  background-color: $blue1;
  padding: 12px;
  font-size: 12pt;
  color: #fff;
  border-radius: 5px;

  &:hover {
    background-color: $blue3;
  }
}

/*.btn-success {
  margin-top: 5px;
  background-color: $green;
  outline: none;
  border: 0;
  padding: 10px 15px;
  color: $black;
}*/

.btn-delete {
  padding: 10px 15px;
  background-color: $red;
  border: 1px solid $red;
  border-radius: 4px;
  font-size: 14pt;

  &:hover {
    background-color: $blueLink;
    border: 1px solid $blueLink;
    color: #fff;
  }
}

/*.btn-success:hover {
  background-color: $gray;
}*/

.locale-box {
  display: flex;
  flex-direction: row;

  .locales {
    min-width: 200px;
    max-width: 200px;
  }

  .locale {
    margin: 10px;
    margin-right: 20px;
    border-bottom: 1px solid $gray2;

    a {
      display: block;
      margin-bottom: 10px;
    }
  }
}


.product-langs {
  display: flex;
  flex-direction: row;
  float: right;

  .lang {
    margin: 10px;
  }
}


.content {
  min-height: 600px;
  padding: 0 15px;
  //max-width: 1000px;
  margin: auto;
}

footer {
  min-height: 120px;
  box-shadow: 8px 6px 6px 6px rgba(0, 0, 0, 0.2);

  .container {
    padding: 25px;

    .logo {
      max-width: 190px;
    }
  }

}


/**
  Start loader
 */
.lds-spinner {
  color: black;
  display: inline-block;
  width: 80px;
  height: 80px;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 999999;
  transform: translate(-50%, -50%);
}

.lds-spinner-hide {
  display: none;
}


.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: black;
}

.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/**
 Konec loader
 */

@media only screen and (max-width: 800px) {
  .col-2 {
    width: 100%;
  }


}

/*
Wyswig start
 */

.rdw-option-wrapper {
  border: 1px solid #F1F1F1;
  padding: 5px;
  min-width: 25px;
  height: 20px;
  border-radius: 2px;
  margin: 0 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: white;
  text-transform: capitalize;
}

.rdw-option-wrapper:hover {
  box-shadow: 1px 1px 0px #BFBDBD;
}

.rdw-option-wrapper:active {
  box-shadow: 1px 1px 0px #BFBDBD inset;
}

.rdw-option-active {
  box-shadow: 1px 1px 0px #BFBDBD inset;
}

.rdw-option-disabled {
  opacity: 0.3;
  cursor: default;
}

.rdw-dropdown-wrapper {
  height: 30px;
  background: white;
  cursor: pointer;
  border: 1px solid #F1F1F1;
  border-radius: 2px;
  margin: 0 3px;
  text-transform: capitalize;
  background: white;
}

.rdw-dropdown-wrapper:focus {
  outline: none;
}

.rdw-dropdown-wrapper:hover {
  box-shadow: 1px 1px 0px #BFBDBD;
  background-color: #FFFFFF;
}

.rdw-dropdown-wrapper:active {
  box-shadow: 1px 1px 0px #BFBDBD inset;
}

.rdw-dropdown-carettoopen {
  height: 0px;
  width: 0px;
  position: absolute;
  top: 35%;
  right: 10%;
  border-top: 6px solid black;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

.rdw-dropdown-carettoclose {
  height: 0px;
  width: 0px;
  position: absolute;
  top: 35%;
  right: 10%;
  border-bottom: 6px solid black;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

.rdw-dropdown-selectedtext {
  display: flex;
  position: relative;
  height: 100%;
  align-items: center;
  padding: 0 5px;
}

.rdw-dropdown-optionwrapper {
  z-index: 100;
  position: relative;
  border: 1px solid #F1F1F1;
  width: 98%;
  background: white;
  border-radius: 2px;
  margin: 0;
  padding: 0;
  max-height: 250px;
  overflow-y: scroll;
}

.rdw-dropdown-optionwrapper:hover {
  box-shadow: 1px 1px 0px #BFBDBD;
  background-color: #FFFFFF;
}

.rdw-dropdownoption-default {
  min-height: 25px;
  display: flex;
  align-items: center;
  padding: 0 5px;
}

.rdw-dropdownoption-highlighted {
  background: #F1F1F1;
}

.rdw-dropdownoption-active {
  background: #f5f5f5;
}

.rdw-dropdownoption-disabled {
  opacity: 0.3;
  cursor: default;
}

.rdw-inline-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  flex-wrap: wrap
}

.rdw-inline-dropdown {
  width: 50px;
}

.rdw-inline-dropdownoption {
  height: 40px;
  display: flex;
  justify-content: center;
}

.rdw-block-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  flex-wrap: wrap
}

.rdw-block-dropdown {
  width: 110px;
}

.rdw-fontsize-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  flex-wrap: wrap
}

.rdw-fontsize-dropdown {
  min-width: 40px;
}

.rdw-fontsize-option {
  display: flex;
  justify-content: center;
}

.rdw-fontfamily-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  flex-wrap: wrap
}

.rdw-fontfamily-dropdown {
  width: 115px;
}

.rdw-fontfamily-placeholder {
  white-space: nowrap;
  max-width: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rdw-fontfamily-optionwrapper {
  width: 140px;
}

.rdw-list-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  flex-wrap: wrap
}

.rdw-list-dropdown {
  width: 50px;
  z-index: 90;
}

.rdw-list-dropdownOption {
  height: 40px;
  display: flex;
  justify-content: center;
}

.rdw-text-align-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  flex-wrap: wrap
}

.rdw-text-align-dropdown {
  width: 50px;
  z-index: 90;
}

.rdw-text-align-dropdownOption {
  height: 40px;
  display: flex;
  justify-content: center;
}

.rdw-right-aligned-block {
  text-align: right;
}

.rdw-left-aligned-block {
  text-align: left !important;
}

.rdw-center-aligned-block {
  text-align: center !important;
}

.rdw-justify-aligned-block {
  text-align: justify !important;
}

.rdw-right-aligned-block > div {
  display: inline-block;
}

.rdw-left-aligned-block > div {
  display: inline-block;
}

.rdw-center-aligned-block > div {
  display: inline-block;
}

.rdw-justify-aligned-block > div {
  display: inline-block;
}

.rdw-colorpicker-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  position: relative;
  flex-wrap: wrap
}

.rdw-colorpicker-modal {
  position: absolute;
  top: 35px;
  left: 5px;
  display: flex;
  flex-direction: column;
  width: 175px;
  height: 175px;
  border: 1px solid #F1F1F1;
  padding: 15px;
  border-radius: 2px;
  z-index: 100;
  background: white;
  box-shadow: 3px 3px 5px #BFBDBD;
}

.rdw-colorpicker-modal-header {
  display: flex;
  padding-bottom: 5px;
}

.rdw-colorpicker-modal-style-label {
  font-size: 15px;
  width: 50%;
  text-align: center;
  cursor: pointer;
  padding: 0 10px 5px;
}

.rdw-colorpicker-modal-style-label-active {
  border-bottom: 2px solid #0a66b7;
}

.rdw-colorpicker-modal-options {
  margin: 5px auto;
  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  overflow: scroll;
}

.rdw-colorpicker-cube {
  width: 22px;
  height: 22px;
  border: 1px solid #F1F1F1;
}

.rdw-colorpicker-option {
  margin: 3px;
  padding: 0;
  min-height: 20px;
  border: none;
  width: 22px;
  height: 22px;
  min-width: 22px;
  box-shadow: 1px 2px 1px #BFBDBD inset;
}

.rdw-colorpicker-option:hover {
  box-shadow: 1px 2px 1px #BFBDBD;
}

.rdw-colorpicker-option:active {
  box-shadow: -1px -2px 1px #BFBDBD;
}

.rdw-colorpicker-option-active {
  box-shadow: 0px 0px 2px 2px #BFBDBD;
}

.rdw-link-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  position: relative;
  flex-wrap: wrap
}

.rdw-link-dropdown {
  width: 50px;
}

.rdw-link-dropdownOption {
  height: 40px;
  display: flex;
  justify-content: center;
}

.rdw-link-dropdownPlaceholder {
  margin-left: 8px;
}

.rdw-link-modal {
  position: absolute;
  top: 35px;
  left: 5px;
  display: flex;
  flex-direction: column;
  width: 235px;
  height: 205px;
  border: 1px solid #F1F1F1;
  padding: 15px;
  border-radius: 2px;
  z-index: 100;
  background: white;
  box-shadow: 3px 3px 5px #BFBDBD;
}

.rdw-link-modal-label {
  font-size: 15px;
}

.rdw-link-modal-input {
  margin-top: 5px;
  border-radius: 2px;
  border: 1px solid #F1F1F1;
  height: 25px;
  margin-bottom: 15px;
  padding: 0 5px;
}

.rdw-link-modal-input:focus {
  outline: none;
}

.rdw-link-modal-buttonsection {
  margin: 0 auto;
}

.rdw-link-modal-target-option {
  margin-bottom: 20px;
}

.rdw-link-modal-target-option > span {
  margin-left: 5px;
}

.rdw-link-modal-btn {
  margin-left: 10px;
  width: 75px;
  height: 30px;
  border: 1px solid #F1F1F1;
  border-radius: 2px;
  cursor: pointer;
  background: white;
  text-transform: capitalize;
}

.rdw-link-modal-btn:hover {
  box-shadow: 1px 1px 0px #BFBDBD;
}

.rdw-link-modal-btn:active {
  box-shadow: 1px 1px 0px #BFBDBD inset;
}

.rdw-link-modal-btn:focus {
  outline: none !important;
}

.rdw-link-modal-btn:disabled {
  background: #ece9e9;
}

.rdw-link-dropdownoption {
  height: 40px;
  display: flex;
  justify-content: center;
}

.rdw-history-dropdown {
  width: 50px;
}

.rdw-embedded-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  position: relative;
  flex-wrap: wrap
}

.rdw-embedded-modal {
  position: absolute;
  top: 35px;
  left: 5px;
  display: flex;
  flex-direction: column;
  width: 235px;
  height: 180px;
  border: 1px solid #F1F1F1;
  padding: 15px;
  border-radius: 2px;
  z-index: 100;
  background: white;
  justify-content: space-between;
  box-shadow: 3px 3px 5px #BFBDBD;
}

.rdw-embedded-modal-header {
  font-size: 15px;
  display: flex;
}

.rdw-embedded-modal-header-option {
  width: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.rdw-embedded-modal-header-label {
  width: 95px;
  border: 1px solid #f1f1f1;
  margin-top: 5px;
  background: #6EB8D4;
  border-bottom: 2px solid #0a66b7;
}

.rdw-embedded-modal-link-section {
  display: flex;
  flex-direction: column;
}

.rdw-embedded-modal-link-input {
  width: 88%;
  height: 35px;
  margin: 10px 0;
  border: 1px solid #F1F1F1;
  border-radius: 2px;
  font-size: 15px;
  padding: 0 5px;
}

.rdw-embedded-modal-link-input-wrapper {
  display: flex;
  align-items: center;
}

.rdw-embedded-modal-link-input:focus {
  outline: none;
}

.rdw-embedded-modal-btn-section {
  display: flex;
  justify-content: center;
}

.rdw-embedded-modal-btn {
  margin: 0 3px;
  width: 75px;
  height: 30px;
  border: 1px solid #F1F1F1;
  border-radius: 2px;
  cursor: pointer;
  background: white;
  text-transform: capitalize;
}

.rdw-embedded-modal-btn:hover {
  box-shadow: 1px 1px 0px #BFBDBD;
}

.rdw-embedded-modal-btn:active {
  box-shadow: 1px 1px 0px #BFBDBD inset;
}

.rdw-embedded-modal-btn:focus {
  outline: none !important;
}

.rdw-embedded-modal-btn:disabled {
  background: #ece9e9;
}

.rdw-embedded-modal-size {
  align-items: center;
  display: flex;
  margin: 8px 0;
  justify-content: space-between;
}

.rdw-embedded-modal-size-input {
  width: 80%;
  height: 20px;
  border: 1px solid #F1F1F1;
  border-radius: 2px;
  font-size: 12px;
}

.rdw-embedded-modal-size-input:focus {
  outline: none;
}

.rdw-emoji-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  position: relative;
  flex-wrap: wrap
}

.rdw-emoji-modal {
  overflow: auto;
  position: absolute;
  top: 35px;
  left: 5px;
  display: flex;
  flex-wrap: wrap;
  width: 235px;
  height: 180px;
  border: 1px solid #F1F1F1;
  padding: 15px;
  border-radius: 2px;
  z-index: 100;
  background: white;
  box-shadow: 3px 3px 5px #BFBDBD;
}

.rdw-emoji-icon {
  margin: 2.5px;
  height: 24px;
  width: 24px;
  cursor: pointer;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rdw-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.rdw-spinner > div {
  width: 12px;
  height: 12px;
  background-color: #333;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.rdw-spinner .rdw-bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.rdw-spinner .rdw-bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0)
  }
  40% {
    -webkit-transform: scale(1.0)
  }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}

.rdw-image-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  position: relative;
  flex-wrap: wrap
}

.rdw-image-modal {
  position: absolute;
  top: 35px;
  left: 5px;
  display: flex;
  flex-direction: column;
  width: 235px;
  border: 1px solid #F1F1F1;
  padding: 15px;
  border-radius: 2px;
  z-index: 100;
  background: white;
  box-shadow: 3px 3px 5px #BFBDBD;
}

.rdw-image-modal-header {
  font-size: 15px;
  margin: 10px 0;
  display: flex;
}

.rdw-image-modal-header-option {
  width: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.rdw-image-modal-header-label {
  width: 80px;
  background: #f1f1f1;
  border: 1px solid #f1f1f1;
  margin-top: 5px;
}

.rdw-image-modal-header-label-highlighted {
  background: #6EB8D4;
  border-bottom: 2px solid #0a66b7;
}

.rdw-image-modal-upload-option {
  width: 100%;
  color: gray;
  cursor: pointer;
  display: flex;
  border: none;
  font-size: 15px;
  align-items: center;
  justify-content: center;
  background-color: #f1f1f1;
  outline: 2px dashed gray;
  outline-offset: -10px;
  margin: 10px 0;
  padding: 9px 0;
}

.rdw-image-modal-upload-option-highlighted {
  outline: 2px dashed #0a66b7;
}

.rdw-image-modal-upload-option-label {
  cursor: pointer;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.rdw-image-modal-upload-option-label span {
  padding: 0 20px;
}

.rdw-image-modal-upload-option-image-preview {
  max-width: 100%;
  max-height: 200px;
}

.rdw-image-modal-upload-option-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.rdw-image-modal-url-section {
  display: flex;
  align-items: center;
}

.rdw-image-modal-url-input {
  width: 90%;
  height: 35px;
  margin: 15px 0 12px;
  border: 1px solid #F1F1F1;
  border-radius: 2px;
  font-size: 15px;
  padding: 0 5px;
}

.rdw-image-modal-btn-section {
  margin: 10px auto 0;
}

.rdw-image-modal-url-input:focus {
  outline: none;
}

.rdw-image-modal-btn {
  margin: 0 5px;
  width: 75px;
  height: 30px;
  border: 1px solid #F1F1F1;
  border-radius: 2px;
  cursor: pointer;
  background: white;
  text-transform: capitalize;
}

.rdw-image-modal-btn:hover {
  box-shadow: 1px 1px 0px #BFBDBD;
}

.rdw-image-modal-btn:active {
  box-shadow: 1px 1px 0px #BFBDBD inset;
}

.rdw-image-modal-btn:focus {
  outline: none !important;
}

.rdw-image-modal-btn:disabled {
  background: #ece9e9;
}

.rdw-image-modal-spinner {
  position: absolute;
  top: -3px;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}

.rdw-image-modal-alt-input {
  width: 70%;
  height: 20px;
  border: 1px solid #F1F1F1;
  border-radius: 2px;
  font-size: 12px;
  margin-left: 5px;
}

.rdw-image-modal-alt-input:focus {
  outline: none;
}

.rdw-image-modal-alt-lbl {
  font-size: 12px;
}

.rdw-image-modal-size {
  align-items: center;
  display: flex;
  margin: 8px 0;
  justify-content: space-between;
}

.rdw-image-modal-size-input {
  width: 40%;
  height: 20px;
  border: 1px solid #F1F1F1;
  border-radius: 2px;
  font-size: 12px;
}

.rdw-image-modal-size-input:focus {
  outline: none;
}

.rdw-image-mandatory-sign {
  color: red;
  margin-left: 3px;
  margin-right: 3px;
}

.rdw-remove-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  position: relative;
  flex-wrap: wrap
}

.rdw-history-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  flex-wrap: wrap
}

.rdw-history-dropdownoption {
  height: 40px;
  display: flex;
  justify-content: center;
}

.rdw-history-dropdown {
  width: 50px;
}

.rdw-link-decorator-wrapper {
  position: relative;
}

.rdw-link-decorator-icon {
  position: absolute;
  left: 40%;
  top: 0;
  cursor: pointer;
  background-color: white;
}

.rdw-mention-link {
  text-decoration: none;
  color: #1236ff;
  background-color: #f0fbff;
  padding: 1px 2px;
  border-radius: 2px;
}

.rdw-suggestion-wrapper {
  position: relative;
}

.rdw-suggestion-dropdown {
  position: absolute;
  display: flex;
  flex-direction: column;
  border: 1px solid #F1F1F1;
  min-width: 100px;
  max-height: 150px;
  overflow: auto;
  background: white;
  z-index: 100;
}

.rdw-suggestion-option {
  padding: 7px 5px;
  border-bottom: 1px solid #f1f1f1;
}

.rdw-suggestion-option-active {
  background-color: #F1F1F1;
}

.rdw-hashtag-link {
  text-decoration: none;
  color: #1236ff;
  background-color: #f0fbff;
  padding: 1px 2px;
  border-radius: 2px;
}

.rdw-image-alignment-options-popup {
  position: absolute;
  background: white;
  display: flex;
  padding: 5px 2px;
  border-radius: 2px;
  border: 1px solid #F1F1F1;
  width: 105px;
  cursor: pointer;
  z-index: 100;
}

.rdw-alignment-option-left {
  justify-content: flex-start;
}

.rdw-image-alignment-option {
  height: 15px;
  width: 15px;
  min-width: 15px;
}

.rdw-image-alignment {
  position: relative;
}

.rdw-image-imagewrapper {
  position: relative;
}

.rdw-image-center {
  display: flex;
  justify-content: center;
}

.rdw-image-left {
  display: flex;
}

.rdw-image-right {
  display: flex;
  justify-content: flex-end;
}

.rdw-image-alignment-options-popup-right {
  right: 0;
}

.rdw-editor-main {
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
}

.rdw-editor-toolbar {
  padding: 6px 5px 0;
  border-radius: 2px;
  border: 1px solid #F1F1F1;
  display: flex;
  justify-content: flex-start;
  background: white;
  flex-wrap: wrap;
  font-size: 15px;
  margin-bottom: 5px;
  user-select: none;
}

.public-DraftStyleDefault-block {
  margin: 1em 0;
}

.rdw-editor-wrapper:focus {
  outline: none;
}

.rdw-editor-wrapper {
  box-sizing: content-box;
}

.rdw-editor-main blockquote {
  border-left: 5px solid #f1f1f1;
  padding-left: 5px;
}

.rdw-editor-main pre {
  background: #f1f1f1;
  border-radius: 3px;
  padding: 1px 10px;
}


div.DraftEditor-root {
  border: 1px solid #80bdff;
}

/*wyswig end*/

.label-info{
  span{
    color: #7c8dfd;
  }
  
}
