@import "../../globalVar";
.tagy{
  .tagBody{
    margin-top: 15px;
    .search{
      margin-bottom: 10px;
      input{
        min-width: 300px;
      }

    }
  }
  .tagContent{
    padding: 10px;
    background-color: $lightGray;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.edit{
  .tagContent{
    padding: 10px;
    background-color: $lightGray;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

