@import "../../../globalVar";

.langs{
  display: flex;
  flex-direction: row;
  input[type="radio"].toggle {
    display: none;
  }

  label{
    padding: 3px 5px;
    margin: 3px;
    cursor: pointer;
    border: 1px solid $blue1;
    color: $blue1;
    display: inline-block;
    width: 30px;
    &:hover{
      background-color: $blueLink !important;
      color: #fff !important;
    }
  }

  label.checked{
    background-color: $blue1;
    color: #fff;
  }
}



