.photoStatus{
  font-size: 10pt;
  .icon{
    margin-right: 5px;
  }
  
  a {
    margin-left: 4px;
    color: gray;
    
    &:visited{
      color: gray;
    }
    
    &:hover{
      color: black;
    }
  }
  
  .found{
    color: green;
    display: flex;
    flex-direction: row;
  }
  
  .not-found{
    color: red;
  }
}