@import "../../../globalVar";

.reportForecastStocks{
  .label-info{
    margin-top: 4px;
    margin-left: 10px;
  }
  .big-checkbox {
    
    input{
      width: 20px; 
      height: 20px;
    }
    label{
      margin-left: 10px;
      margin-top: 3px;
    }
   
  }
  .cont{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    background-color: $lightGray;
    padding: 20px 20px;
    width: 100%;
    border-radius: 5px;
    .item{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;
      box-sizing: border-box;
      .count{
        font-weight: bold;
        display:  flex;
        flex-direction: row;
        align-items: center;
        padding-bottom: 5px;
        width: 5%;
        box-sizing: border-box;
        color: $bs-primary;

      }
    }

    .item2{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      background-color: #fff;
      padding: 5px 10px;
      border-radius: 3px;
      align-items: center;
      margin-bottom: 6px;
      box-sizing: border-box;
      width: 95%;

      .name{
        display:  flex;
        flex-direction: row;
        align-items: center;
      }
      .i{
        color: $bs-danger;
        cursor: pointer;
        font-size: 14pt;
      }
    }
  }
}