@import "../../globalVar";

.detailTag{
  .langsTag{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    .item{
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 10px;
      .key{
        width: 60px;
        text-align: center;
        font-size: 16pt;
      }
      input{
        width: 250px;
      }

    }
  }
  .poznLabel{
    display: block;
    width: 100%;
    margin-top: 10px;
  }
  textarea{
    width: 100%;
    height: 50px;
  }
  .ctrl{
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    button{
      margin: 5px;
    }
  }
}