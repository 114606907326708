@import '../../globalVar.scss';

.esoBox{

  padding: 10px;

  h2{
    width: 100%;
    border-radius: 5px;
    color: $bs-primary;
    font-size: 18px;
    padding: 12px;
    background-color: $lightGray;
    border: 1px solid #fff;
    margin: 5px 8px 5px 8px;
    //box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.2);
    font-weight: bold;

  }

  .cont{
    display: flex;
    flex-direction: column;
    .item{
      padding: 12px;
      background-color: $lightGray;
      //border: 1px solid #fff;
      border-radius: 5px;
      margin: 5px 8px;
      //box-shadow: 0 0 5px -1px rgba(0,0,0,0.2);

      .amount{
        padding: 5px;
        background-color: #fff;
        border-radius: 5px;
        width: 160px;
        text-align: center;
        margin-top: 20px;
        font-size: 14pt;
        //font-weight: bold;
        color: black;
        .unit{
          margin-left: 5px;
          display: inline-block;
          color: $bs-primary;
        }
      }
    }
    .box{
      padding: 30px 12px;
      display: flex;
      flex-direction: column;
      min-width: 200px;
      align-content: center;
      align-items: center;
      color: $blackLight;
      .i{
        margin: 5px;
        font-size: 22pt;
      }
    }
    .vol{
      color: #fff;
      background-color: $bs-success;

    }
    .blok{
      color: #fff;
      background-color: $bs-danger;
    }
    .obj{
      background-color: $bs-info;
    }


  }
  .skladyCont{
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    .item{
      width: 100%;
      margin: 5px 0;
      background-color: $lightGray;
      padding: 15px 20px;
      .heading{
        margin-bottom: 5px;
      }
      .item2{

        .key{
          width: 100px;
          display: inline-block;
          margin: 2px 0;
        }
        .val{
          width: 100px;
          text-align: right;
          color: $bs-primary;
          display: inline-block;
        }
      }
    }
  }
}