@import "src/globalVar";

.documentsOnProduct {
  .documents {
    .cont {
      margin: 10px 0;
      .contItems {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;

        .item {
          width: 192px;
          padding: 0px 10px;
          margin: 3px;
          border-radius: 3px;
          background-color: $bs-light;
          border: 1px solid $bs-light;
          display: flex;
          flex-direction: column;
          &:hover{
            border-color: $bs-primary;
          }
          .item2{
            margin-bottom: 5px;
          }
          .ctrl{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            .icon{
              font-size: 44pt;
              color: $bs-secondary;
            }
            .btns{
              display: flex;
              flex-wrap: wrap;
              align-items: flex-start;
              margin-top: 15px;
              margin-left: 2px;
              width: 114px;
            }
          }
          
          .fileName{
            color: $bs-secondary;
            height: 80px;
            word-wrap: anywhere;
          }
          
          .fileSize{
            font-size: 11pt;
            color: $bs-secondary;
            margin-bottom: 1px;
          }
          
          .title{
            color: $bs-primary;
            height: 50px;
            font-size: 14pt;
          }
          
          .infoBadges{
            height: 25px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: flex-start;
            .badge{
              margin: 2px;
            }
          }

        }
        
      }
    }
  }
}