@import "../../globalVar.scss";

.addDocument{
  .cont{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    background-color: $lightGray;
    padding: 20px 20px;
    width: 100%;
    border-radius: 5px;
    .item{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;
      box-sizing: border-box;
      .count{
        font-weight: bold;
        display:  flex;
        flex-direction: row;
        align-items: center;
        padding-bottom: 5px;
        width: 5%;
        box-sizing: border-box;
        color: $bs-primary;

      }
    }
    
    .item2{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      background-color: #fff;
      padding: 5px 10px;
      border-radius: 3px;
      align-items: center;
      margin-bottom: 6px;
      box-sizing: border-box;
      width: 95%;
     
      .name{
        display:  flex;
        flex-direction: row;
        align-items: center;
      }
      .i{
        color: $bs-danger;
        cursor: pointer;
        font-size: 14pt;
      }
    }
  }
  .file{
    padding: 20px;
    color: $bs-secondary;
    background-color: $bs-light;
    width: 100%;
    .icon{
      margin-bottom: 10px;
      width: 100%;
    }
    .i{
      font-size: 30pt;
      
    }
    
  }
  .debug{
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 10px;
    background-color: $bs-light;
    margin-top: 50px;
    font-size: 11pt;
    color: $gray;
    font-family: monospace;
    .key{
      display: inline-block;
      width: 170px;
    }
    .value{
      display: inline-block;
      margin-left: 10px;
    }
    .bson{
      display: flex;
      flex-direction: column;
      .bsonItem{
        display: inline-block;
        margin-bottom: 5px;
        .key{
          width: 170px;
        }
        .value{
          display: inline-block;
          margin-left: 10px ;
        }
      }
      
    }
  }
  
  
}