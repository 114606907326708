@import "../globalVar.scss";

.loginForm {
  width: 500px;
  padding: 30px 40px 50px 40px;
  margin: 50px auto 0 auto;
  //background-color: $bs-light;
  box-shadow: 0 3px 9px -4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  border: 1px solid #f6f4ff;
  border-radius: 10px;
  background-color: #fff;
  .item-row{
    margin-bottom: 10px;
    width: 85%;
    
  }
  input{
    padding: 7px 5px;
    border-radius: 5px;
    border: 1px solid $bs-primary;
    width: 100%;
  }
  label{
    margin-bottom: 0;
    color: $bs-secondary;
  }
  .logo{
    width: 180px;
    margin-bottom: 40px;
    opacity: 0.8;
  }
  .iBlogo{
    width: 80px;
  }
  h1{
    margin-top: 8px;
    font-weight: normal;
  }
  .passRecovery{
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $bs-secondary;
    text-decoration: underline;
    cursor: pointer;
    &:hover{
      color: $bs-primary;
    }
  }
}

.loginCont{
  width: 100%;
}

@media screen and (max-width: 992px) {
  .loginForm {
    width: 90%;
    margin: 12px auto;
  }
}

